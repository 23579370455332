<template>
  <v-container fluid>
    <v-row
      justify="space-between"
    >
      <v-col
        cols="auto"
      >
        <v-btn
          color="primary"
          elevation="1"
          v-if="userAccess.canCreate"
          @click="dialog = true"
        >
          {{$_strings.areaCode.ADD_TEXT}}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="5"
        md="3"
      >
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          :placeholder="$_strings.areaCode.SEARCHNO"
          v-model="searchText"
          @keyup.enter="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="search">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoadingList"
          :headers="headers"
          :items="items"
          item-key="id"
          :server-items-length="areaCodeListTotalEntry"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
        >
          <template v-slot:[`item.actionColumn`]="{item}">
            <v-tooltip top v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="()=> editData(item)" >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="userAccess.canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  v-bind="attrs" v-on="on" x-small icon color="red" @click="()=> deleteData(item)" @click.stop.prevent>
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="700"
      >
        <v-card :loading="isLoading">
          <v-card-title>
            <span class="headtitle-email"><b>Kode Area</b></span>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-subheader>Nama Area</v-subheader>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model.trim="form.name"
                    :rules="rules.areaNameRule"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-subheader>Kode Area</v-subheader>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model.trim="form.code"
                    :rules="rules.areaCodeRule"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  outlined
                  class="mr-2 ml-auto"
                  @click="dialog = false"
                >
                  Batal
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  class="white--text"
                  @click="submit"
                  :disabled="isLoading"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import {
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'areaCode',
  data() {
    return {
      dialog: false,
      isLoadingList: false,
      isLoading: false,
      searchText: this.$route.query.search || '',
      valid: false,
      areaCodeId: '',
      itemStatus: [
        {
          key: 'Aktif',
          value: true,
        },
        {
          key: 'Tidak Aktif',
          value: false,
        },
      ],
      items: [],
      form: {
        name: '',
        code: '',
      },
      rules: {
        areaCodeRule: [
          (value) => {
            const pattern = /[^A-Za-z0-9\s]/g;
            if (!value) return this.$_strings.messages.validation.REQUIRED(this.$_strings.areaCode.AREACODE_NAME);
            if (pattern.test(value)) return this.$_strings.messages.validation.UNIQUE_CHARACTER;
            return true;
          },
        ],
        areaNameRule: [
          (value) => {
            const pattern = /[^A-Za-z0-9\s]/g;
            if (!value) return this.$_strings.messages.validation.REQUIRED(this.$_strings.areaCode.CODE);
            if (pattern.test(value)) return this.$_strings.messages.validation.UNIQUE_CHARACTER;
            return true;
          },
        ],
      },
      headers: [
        {
          text: this.$_strings.areaCode.AREACODE_NAME,
          value: 'name',
          class: 'white--text primary text-capitalize',
          width: '35vw',
        },
        {
          text: this.$_strings.areaCode.CODE,
          value: 'code',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.areaCode.ACTION,
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          sortable: false,
        },
      ],
      pagination: defaultPagination(),
      areaCodeListTotalEntry: 0,
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal && this.areaCodeId) {
        this.$refs.form.reset();
        this.areaCodeId = null;
      }
    },
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoadingList) this.fetchAreaCode();
      },
      deep: true,
    },
  },
  methods: {
    skipEmptyStringObject,
    search() {
      const { search } = this.$route.query;
      if (this.searchText !== search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.searchText,
            page: 1,
          },
        });
      }
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      } else {
        this.fetchAreaCode();
      }
    },
    fetchAreaCode() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: this.searchText,
        sort: handleSortBy({ sortBy, sortDesc }),
      };
      if (filters.name) filters.name = `qlike(${filters.name})`;
      this.isLoadingList = true;
      this.$_services.areaCode.fetchList(this.skipEmptyStringObject(filters))
        .then((result) => {
          this.items = result.data.contents;
          this.areaCodeListTotalEntry = result.data.totalData;
        }).finally(() => {
          this.isLoadingList = false;
        });
    },
    deleteData(item) {
      this.$dialog.warning({
        text: this.$_strings.areaCode.DELETE_CONFIRM_MESSAGE,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((userRes) => {
        if (!userRes) return;
        this.$root.$loading.show();
        this.$_services.areaCode.deleteData(item.id)
          .then(() => {
            this.items.splice(this.items.indexOf(item), 1);
            this.$dialog.notify.success(this.$_strings.areaCode.SUCCESS_DELETE_MESSAGE_TEXT);
          }).finally(() => {
            this.$root.$loading.hide();
          });
      });
    },
    onChangeIsActive(item, event) {
      const { name } = item;
      const form = {
        name,
        isActive: event,
      };
      this.$set(item, 'isLoading', true);
      this.$_services.areaCode.changeStatus(form, item.id)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.areaCode.EDIT_STATUS_MESSAGE_TEXT);
        }).catch(() => {
          this.$nextTick(() => {
            item.isActive = !event;
          });
        }).finally(() => {
          this.$delete(item, 'isLoading');
        });
    },
    editData(item) {
      this.areaCodeId = item.id;
      this.form.name = item.name;
      this.form.code = item.code;
      this.dialog = true;
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      this.$_services.areaCode.createEdit(this.form, this.areaCodeId)
        .then(() => {
          if (this.areaCodeId) {
            this.$dialog.notify.success(this.$_strings.areaCode.EDIT_MESSAGE_TEXT);
          } else {
            this.$dialog.notify.success(this.$_strings.areaCode.SUCCESS_MESSAGE_TEXT);
          }
          this.dialog = false;
          this.$refs.form.reset();
          this.fetchAreaCode();
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip.v-size--default {
    font-size: 12px;
    margin-top: 3px;
}
</style>
